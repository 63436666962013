export let possibleAnswers = [
    "atque",
    "etiam",
    "autem",
    "neque",
    "tamen",
    "nihil",
    "causa",
    "omnia",
    "illud",
    "omnis",
    "solum",
    "posse",
    "saepe",
    "satis",
    "quasi",
    "multa",
    "inter",
    "ipsum",
    "genus",
    "verum",
    "magis",
    "minus",
    "nulla",
    "nomen",
    "tanta",
    "summa",
    "animo",
    "animi",
    "aliud",
    "eadem",
    "certe",
    "eodem",
    "ratio",
    "multo",
    "magna",
    "civis",
    "locum",
    "simul",
    "bello",
    "locus",
    "pater",
    "belli",
    "vitae",
    "forte",
    "urbis",
    "antea",
    "virum",
    "utrum",
    "paene",
    "paulo",
    "prope",
    "video",
    "totum",
    "alter",
    "prima",
    "viris",
    "ferre",
    "longe",
    "credo",
    "multi",
    "licet",
    "manus",
    "recte",
    "domus",
    "usque",
    "legis",
    "sicut",
    "primo",
    "caput",
    "dicis",
    "palam",
    "facti",
    "nonne",
    "secum",
    "adhuc",
    "nimis",
    "agere",
    "poena",
    "plane",
    "copia",
    "velle",
    "nuper",
    "ausus",
    "talis",
    "prius",
    "natus",
    "scire",
    "caelo",
    "bella",
    "forum",
    "fides",
    "amici",
    "motus",
    "artis",
    "odium",
    "terra",
    "extra",
    "ullum",
    "semel",
    "pacis",
    "porro",
    "honos",
    "sacra",
    "nulli",
    "opera",
    "bonum",
    "casus",
    "super",
    "summi",
    "parum",
    "alias",
    "certa",
    "culpa",
    "loqui",
    "plena",
    "aetas",
    "quoad",
    "medio",
    "salus",
    "datum",
    "tecta",
    "regis",
    "novum",
    "mater",
    "valde",
    "navis",
    "falsa",
    "solus",
    "aequo",
    "actum",
    "ferri",
    "longa",
    "pedes",
    "quale",
    "auris",
    "turpe",
    "metus",
    "visum",
    "bonus",
    "forma",
    "media",
    "munus",
    "natum",
    "brevi",
    "dolor",
    "malum",
    "certo",
    "alius",
    "hodie",
    "visus",
    "valet",
    "decem",
    "secus",
    "sermo",
    "ultro",
    "verbi",
    "acies",
    "alium",
    "pauca",
    "finis",
    "mirum",
    "quare",
    "potui",
    "supra",
    "tueri",
    "vocis",
    "labor",
    "sequi",
    "regno",
    "parva",
    "vetus",
    "grave",
    "orbis",
    "sedes",
    "otium",
    "curia",
    "ignis",
    "aeque",
    "divum",
    "telis",
    "caeli",
    "digna",
    "utile",
    "istuc",
    "virgo",
    "debeo",
    "pudor",
    "liber",
    "servi",
    "imago",
    "decus",
    "modus",
    "stare",
    "solis",
    "regia",
    "nefas",
    "harum",
    "hasta",
    "vitio",
    "spero",
    "levis",
    "audio",
    "heres",
    "fundo",
    "habeo",
    "telum",
    "turba",
    "lumen",
    "soleo",
    "aurum",
    "comes",
    "mille",
    "regio",
    "grata",
    "furor",
    "actio",
    "necne",
    "facio",
    "pueri",
    "annus",
    "queri",
    "pauci",
    "illuc",
    "amens",
    "primi",
    "timor",
    "cupio",
    "poeta",
    "capta",
    "pugna",
    "miser",
    "lucri",
    "aedis",
    "fidei",
    "aurea",
    "dudum",
    "eques",
    "fatum",
    "libro",
    "totus",
    "dicto",
    "istud",
    "intra",
    "agmen",
    "oculi",
    "amico",
    "velut",
    "mitto",
    "datus",
    "legio",
    "augur",
    "varia",
    "falso",
    "dubia",
    "coram",
    "intus",
    "minor",
    "error",
    "facis",
    "artus",
    "prior",
    "nimia",
    "curae",
    "servo",
    "volui",
    "soror",
    "famae",
    "latus",
    "numen",
    "acute",
    "regni",
    "dives",
    "recta",
    "laeta",
    "umbra",
    "tergo",
    "venti",
    "aptum",
    "demum",
    "filia",
    "venio",
    "felix",
    "exire",
    "funus",
    "vates",
    "pedum",
    "simus",
    "vulgi",
    "signo",
    "firma",
    "clara",
    "ratum",
    "audax",
    "heros",
    "libri",
    "ortus",
    "aedes",
    "amnis",
    "vulgo",
    "fanum",
    "emere",
    "silva",
    "tenui",
    "noctu",
    "doceo",
    "nemus",
    "altum",
    "adire",
    "fundi",
    "nasci",
    "foris",
    "teneo",
    "summe",
    "aequa",
    "nolle",
    "decet",
    "sinus",
    "gener",
    "actor",
    "novus",
    "natio",
    "audeo",
    "litus",
    "notus",
    "vivus",
    "aeris",
    "corda",
    "ardua",
    "saeva",
    "gessi",
    "fames",
    "fugae",
    "tutum",
    "donec",
    "penes",
    "ortum",
    "miror",
    "volgo",
    "fraus",
    "laeva",
    "retro",
    "memor",
    "equus",
    "aequi",
    "tecti",
    "miles",
    "nudus",
    "muris",
    "caeca",
    "digni",
    "laudo",
    "pudet",
    "certi",
    "vasto",
    "ponto",
    "sonus",
    "clari",
    "volgi",
    "labes",
    "curis",
    "maris",
    "lucis",
    "socer",
    "villa",
    "morum",
    "vivum",
    "salva",
    "senex",
    "vacua",
    "socio",
    "pubes",
    "atqui",
    "durum",
    "campi",
    "acris",
    "breve",
    "cruor",
    "probo",
    "ultra",
    "belua",
    "ducis",
    "aquae",
    "falsi",
    "saxum",
    "ullus",
    "missi",
    "venia",
    "morae",
    "sitis",
    "ocius",
    "nepos",
    "ampla",
    "metuo",
    "risus",
    "freta",
    "emori",
    "inops",
    "debui",
    "notae",
    "sella",
    "absit",
    "timeo",
    "novem",
    "agens",
    "ardor",
    "quies",
    "redeo",
    "nolui",
    "doleo",
    "lacus",
    "notum",
    "rudis",
    "porta",
    "veris",
    "inane",
    "furti",
    "nudum",
    "libet",
    "minae",
    "galea",
    "divae",
    "celsa",
    "opaca",
    "aptus",
    "lecta",
    "arcus",
    "ludus",
    "morbi",
    "ficta",
    "ruina",
    "limen",
    "moneo",
    "plaga",
    "verus",
    "beati",
    "imber",
    "lecti",
    "donum",
    "socia",
    "egens",
    "tutor",
    "malle",
    "color",
    "nempe",
    "sensi",
    "habui",
    "aurae",
    "ensis",
    "letum",
    "umida",
    "paean",
    "asper",
    "pedis",
    "tarda",
    "posui",
    "medii",
    "tenus",
    "egere",
    "lepos",
    "manis",
    "sacer",
    "circo",
    "anima",
    "robur",
    "vario",
    "actus",
    "capti",
    "frugi",
    "pecus",
    "nummi",
    "passi",
    "latum",
    "probe",
    "missa",
    "canes",
    "recti",
    "trans",
    "altus",
    "densa",
    "fulva",
    "vasta",
    "obvia",
    "origo",
    "acuta",
    "mundi",
    "moles",
    "moror",
    "natae",
    "salis",
    "caeco",
    "salsa",
    "vadis",
    "aegra",
    "impia",
    "carum",
    "amare",
    "exsul",
    "fixum",
    "testi",
    "obire",
    "sacro",
    "orare",
    "cella",
    "serva",
    "circa",
    "litis",
    "coepi",
    "malae",
    "solvi",
    "nimbi",
    "orsus",
    "pavor",
    "parce",
    "fetus",
    "fusum",
    "ferox",
    "raeda",
    "senis",
    "undae",
    "malui",
    "ultor",
    "carus",
    "menti",
    "aeger",
    "dicti",
    "metui",
    "caeno",
    "dulce",
    "togae",
    "illic",
    "hiems",
    "pulla",
    "sacri",
    "vacuo",
    "malus",
    "sedis",
    "bonae",
    "tenue",
    "ferum",
    "ausum",
    "firmo",
    "infra",
];